$(document).ready(function(){
	$('.header__nav-trigger--open').click(function(){
		$('body').addClass('nav-slide');
		$(this).addClass('hidden');
	});
	$('.header__nav-trigger--close').click(function(){
		$('body').removeClass('nav-slide');
		$('.header__nav-trigger--open').removeClass('hidden');
	});
});


